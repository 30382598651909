.redpacket-wrapper {
  color: #000;
  background: #F5F6FA;
  overflow: scroll;

  .line {
    height: 10px;
  }

  p {
    margin: 0;
  }

  .loading {
    text-align: center;
    height: 120px;
    width: 200px;
    margin: auto;
    margin-top: 40vh;
    color: #fff;
    line-height: 120px;
    border-radius: 10px;
    font-size: 16px;

    .loading-img {
      width: 100px;
    }
  }

  .top-container {
    background: #fff;
    padding: 16px;
    margin-bottom: 10px;

    .date {
      font-size: 18px;
      font-weight: bold;
    }

    .text {
      color: #666;
      font-size: 13px;
      margin-top: 12px;
    }

    .empty-img {
      width: 180px;
      height: 180px;
      margin-left: calc(50% - 90px);
      margin-top: 20px;
    }

    .empty-text {
      color: #999;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 4px;
    }
  }

  .redpacket-list-wrapper {
    background: #fff;
    padding: 16px 10px 0 10px;
    position: relative;

    .send-title {
      padding: 0 6px;
      color: #222;
      font-size: 14px;
      margin-bottom: 9px;
      line-height: 20px;
      margin-top: 14px;
    }

    .tip {
      background: rgba(219, 0, 7, 0.05);
      height: 24px;
      position: absolute;
      top: 0;
      right: 0;
      color: #DB0007;
      font-size: 12px;
      line-height: 24px;
      border-radius: 0 0 0 18px;
      padding: 0 14px;
    }

    .not-send {
      margin-top: 15px;
    }

    .redpacket-list {
      display: flex;
      flex-wrap: wrap;

      .redpacket-item {
        width: 33.3%;
        text-align: center;
        position: relative;
        margin-bottom: 10px;

        .redpacket-img-block {
          position: relative;
          height: 100%;

          .redpacket-img {
            width: calc(100% - 10px);
            height: 100%;
          }

          .send-out-icon {
            position: absolute;
            width: 55px;
            top: 0;
            height: 46px;
            right: 0;
          }
        }

        .left-count {
          font-size: 11px;
          margin: 0;
          position: absolute;
          bottom: 40px;
          width: 100%;
          text-align: center;
          color: #fff;
          opacity: 0.8;
        }

        .send-btn,
        .disable-send {
          position: absolute;
          bottom: 8px;
          background: #FFBC0D;
          width: 84px;
          height: 26px;
          line-height: 26px;
          border-radius: 26px;
          margin-left: calc(50% - 42px);
          font-size: 13px;
        }

        .disable-send {
          background: #EEEEEE;
          color: #B8B8B8;
        }
      }
    }

    .no-sendlist {
      color: #BBBBC9;
      font-size: 14px;
      margin-left: 6px;
      padding-bottom: 15px;
    }
  }

  .footer-wrapper {
    padding: 10px 16px 16px 16px;
    background: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    // width: calc(100% - 26px);
    border-top: 0.5px solid rgba(0, 0, 0, 0.1);


    .no-redpacket {
      font-size: 18px;
      color: #999;
      font-weight: bold;
      text-align: center;
      line-height: 25px;
      margin-top: 9px;
    }

    .footer-wrapper-block {
      display: flex;
      justify-content: space-between;
      margin-right: 4px;
    }

    .receive-num {
      font-size: 14px;
      margin-bottom: 5px;

      .unsend-num {
        color: #DB0007;
      }
    }

    .text {
      color: #999;
      font-size: 12px;
    }

    .lock-more,
    .no-lock {
      float: right;
      height: 42px;
      width: 150px;
      text-align: center;
      font-weight: bold;
      line-height: 42px;
      border-radius: 42px;
    }

    .lock-more {
      background: #FFBC0D;
    }

    .no-lock {
      background: #EEEEEE;
      color: #B8B8B8;
    }
  }

  .toast-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 50vh;
    top: 30vh;
    z-index: 10000;

    .toast {
      background: rgba(0, 0, 0, 0.6);
      height: 42px;
      color: #fff;
      line-height: 42px;
      border-radius: 6px;
      padding: 0 28px;
      font-size: 14px;
      text-align: center;
    }
  }
}