.qr-test {
  font-family: "PingFangSC-Regular,sans-serif,tahoma";
  padding: 0 20px;
  .header {
    display: flex;
    /*background: green;*/
    /*color: white;*/
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    .title {
      color: #555;
      font-size: 40px;
    }
  }
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    /*flex-direction: column;*/
    text-align: center;
    font-size: 12px;
   .action {
      padding: 10px 40px;
      margin: 10px;
      width: 200px;
      background: #ffbc0d;
      color: #333;
      font-weight: bold;
      border-radius: 5px;
    }
  }
}