.redpacket-activityList-bg {
  background: #F5F6FA;
  height: 100vh;
}

.redpacket-activityList-wrapper {
  color: #000;
  background: #F5F6FA;
  // overflow: scroll;
  padding: 15px;
  .loading {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .empty-img {
    width: 180px;
    height: 180px;
    margin-left: calc(50% - 90px);
    margin-top: 12vh;
  }
  .empty-text {
    color: #999;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 4px;
  }

  .store-view {
    margin-top: -5px;
    margin-bottom: 10px;
    width: 100%;
    background-color: #FFF8E6;
    border: 1px solid #FFBC0D;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 10px;
    
    .store-icon {
      width: 20px;
      height: 20px;
    }
    .store-name {
      color: #222;
      font-size: 12px;
      margin-left: 5px;
      font-weight: bold;
      line-height: 16px;
      flex: 1;
    }
    .store-arrow {
      margin-left: 5px;
      width: 12px;
      height: 12px;
      margin-right: 0px;
    }
  }


  .activity-list-container {   

    .activity-item-wrap {
      border-radius: 10px;
      background-color: #ffffff;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .activity-item-left {
        .activity-time {
          font-size: 18px;
          font-weight: bold;
        }
        .activity-name {
          color: #666;
          font-size: 13px;
          margin-top: 12px;
        }

      }
      .activity-item-right {
          width: 10px;
          height: 10px;
          border-right: 1px solid #666;
          border-bottom: 1px solid #666;
          transform: rotate(-45deg);
      }
    }
  }

  .store-list-container {
    padding-bottom: 63px;

    .store-item-wrap {
      border-radius: 10px;
      background-color: #ffffff;
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 10px;
      position: relative;

      .store-select-icon {
        position: absolute;
        top: 27px;
        left: 15px;
        width: 20px;
        height: 20px;
      }

      .store-image {
        position: absolute;
        top: 15px;
        left: 50px;
        width: 60px;
        height: 45px;
        border-radius: 5px;
      }

      .store-name {
        margin-left: 110px;
        color: #222;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
      }

      .store-code {
        margin-left: 110px;
        margin-top: 4px;
        color: #333;
        font-size: 13px;
        line-height: 17px;
      }

      .store-time {
        margin-left: 110px;
        margin-top: 4px;
        color: #999;
        font-size: 13px;
        line-height: 17px;
      }
    }
  }

  .confirm-button {
    z-index: 1;
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100vw;
    height: 53px;
    background-color: #ffffff;

    .confirm-button-view {
      margin-left: 20px;
      margin-top: 5px;
      width: calc(100vw - 40px);
      height: 42px;
      background-color: #ffbc0d;
      border-radius: 21px;
      color: #222;
      text-align: center;
      line-height: 42px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}