.ant-drawer-body {
  height: 100%;
  overflow: hidden !important;
}

.modal-login,
.modal-verifycode {
  height: 100%;
  position: relative;
  padding: 0 30px;
  margin: 0 auto;
  font-family: PingFangSC-Medium;

  .header {
    margin: 0 auto;
    padding-top: 45px;
    text-align: center;
    margin-bottom: 70px;

    h3 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0;
    }

    .logo {
      margin: 0 auto 16px auto;

      img {
        width: 46px;
      }
    }
  }

  .code-body {
    font-weight: 400;
    font-size: 12px;

    .top-line {
      display: flex;
      justify-content: space-between;
      color: #999999;
      margin-bottom: 14px;

      .resend-smscode-action {
        color: #417EFF;
      }

      .smscode-action-disabled {
        color: #999999;
      }
    }

    .message-line {
      margin-top: 20px;
      color: #999999;
    }

    .vcode-input-box {
      display: flex;
      justify-content: space-between;

      input.digital-input {
        font-size: 28px;
        font-family: Futura-Medium;
        color: #333;
        width: 42px;
        line-height: 52px;
        text-align: center;
        border-bottom: 1px solid #D8D8D8;
        border-radius: 0;
        ime-mode: disabled;
      }

      input.digital-input:focus {
        border-bottom-color: #222;
      }
    }
  }

  .body {
    .mobile-input-box {
      margin-bottom: 40px;
      position: relative;

      .ant-btn {
        box-shadow: none;
      }

      .ant-input-affix-wrapper {
        border-radius: initial;
        border-bottom: 1px solid #C3C3C3;
      }

      .ant-input-affix-wrapper:hover {
        border-bottom-color: #999;
      }

      input {
        font-family: Futura-Medium;
        color: #333;
        height: 35px;
        text-indent: 7px;
      }

      input:focus {
        outline: none;
      }

      input::-webkit-input-placeholder {
        font-family: PingFangSC-Light;
        font-weight: 500;
        font-size: 17px;
        color: #A8A8AF;
      }

      input::-moz-placeholder {
        font-size: 18px;
      }

      input::-ms-input-placeholder {
        font-size: 18px;
      }

      input::input-placeholder {
        font-size: 18px;
      }

      .input-error {
        color: #DB0007;
        font-size: 11px;
        margin-top: 9px;
        font-family: PingFang-SC-Medium;
      }
    }

    .mobile-input-box__bottom {
      .ant-input-affix-wrapper {
        border-bottom: 1px solid #DB0007 !important;
      }
    }

    .button-box {
      text-align: center;

      button {
        font-size: 18px;
        background: #F2F2F2;
        color: #C8C8C8;
        border: 0;
        border-radius: 30px;
        width: 100%;
        height: 42px;
      }

      button:focus {
        outline: none;
      }

      .btnEnabled {
        background: #FFBC0D;
        color: #222222;
      }
    }

    .input-box {
      display: flex;
      position: relative;
      align-items: center;

      .button-yan {
        right: 0;
        border: 0;
        font-size: 16px;
        text-align: right;
        line-height: 22px;
        position: absolute;
        color: rgba(153, 153, 153, 100);
        font-family: PingFangSC-Regular;
      }

      .button-yan__get {
        color: rgba(65, 126, 255, 100);
      }
    }
  }

  .footer {
    padding: 0 30px;
    font-size: 12px;
    font-family: PingFangSC-Regular;
    position: absolute;
    color: #666;
    bottom: 15px;
    left: 0;
    right: 0;

    a {
      font-size: inherit;
    }

    .term-row {
      display: flex;
      align-items: flex-start;
    }

    .term-row-1 {
      margin-bottom: 22px;
    }

    img.check-icon {
      padding-top: 1px;
      width: 16px;
      margin-right: 5px;
    }
  }
}

.toastBox .toastContent {
  max-width: 80%;
}