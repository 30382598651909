.accept-invite {
  width: 100vw;
  height: 100vh;
  position: relative;
  .content-container {
    position: absolute;
    bottom: 80px;
    z-index: 3;
    width: 100%;
  }
  &-cover {
    width: 100%;
  }
  img {
    width: 100%;
  }
  .get-btn {
    cursor: pointer;
    border: none;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    margin: 20px auto 0;
    border-radius: 16px;
    background-color: #d84437;
    color: #fff;
    &-disable {
      background-color: #e6e6e6;
      color: #9e9e9e;
    }
  }
  .old-people {
    text-align: center;
    margin-top: 10px;
  }
  .accept-invite-btn-group {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .btn {
      width: 115px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f4f4f4;
      color: #000;
      border: 1px solid #000;
      border-radius: 17px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  .invite-content {
    height: 100px;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-top: 20px;
    background-color: #ac49e3;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}
