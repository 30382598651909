
  @keyframes playing {
    0% {
        transform: rotate(0deg)
    }
  
    to {
        transform: rotate(1turn)
    }
  }
  
  @keyframes up-arrow {
    0% {
        opacity: 0
    }
  
    50% {
        transform: translate3d(0,-15px,0);
        opacity: 1
    }
  
    to {
        transform: translate3d(0,-25px,0);
        opacity: 0
    }
  }
  