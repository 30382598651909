.aks-root {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  .aks-over-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .aks-over-img {
      width: 146PX;
      height: 180PX;
    }
  
    .aks-over-title {
      font-family: PingFangSC-Semibold;
      font-size: 16Px;
      color: #222;
      font-weight: bold;
      margin: 20Px 0 9PX;
    }
  
    .aks-over-subtitle {
      font-family: PingFangSC-Regular;
      font-size: 16Px;
      color: #222;
    }
  }

  .aks-action-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;

    .aks-join-activity {
      width: 237PX;
      height: 63PX;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .aks-prize-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 62%;

    .aks-prize-name {
      font-size: 24PX;
      font-family: PingFangHK-Medium;
      color: #000;
      margin-bottom: 2PX;
    }

    .aks-use-tips {
      font-size: 14PX;
      font-family: PingFangHK-Regular;
      color: #999;
    }

  }

  .aks-activity-rule {
    width: 48PX;
    height: 26PX;
    position: absolute;
    top: 25PX;
    right: 0;
  }
}

.aks-modal {
  margin: 0 25PX;

  .ant-modal-content {
    width: calc(100vw - 46PX);
    background-color: transparent;
    box-shadow: none;
    white-space: pre-line;

    .ant-modal-body {
      display: flex;
      height: calc(100vh * 0.58);
      background-image: url(https://cdn-test.mcdchina.net/gallery/85a730d013f6913f.png);
      // background-size: contain;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 62PX 20PX 24PX;

      .aks-body-container {
        width: calc(100vw - 84PX);
        display: flex;
        flex-direction: column;
        flex: 1;

        .aks-modal-container {
          padding: 0 3PX;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .aks-modal-contant {
          display: flex;
          height: 84.1%;
          overflow: scroll;
          align-items: flex-start;
          font-family: PingFangSC-Regular;
          font-size: 13PX;
          color: #222;
          margin: 0 0 15PX;
        }

        .aks-modal-footer {
          .aks-modal-footer-action {
            width: 180PX;
            height: 38Px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #CA3227;
            font-family: PingFangSC-Medium;
            font-size: 16PX;
            color: #FFF;
            border-radius: 50PX;
          }
        }
      }
    }
  }
}
