.qr-store {
  font-family: "PingFangSC-Regular,sans-serif,tahoma";

  .header {
    display: flex;
    /*background: green;*/
    /*color: white;*/
    justify-content: center;
    align-items: center;
    padding-top: 150px;

    .loading {
      height: 100px;
    }

    .logo {
      width: 200px;
    }

    .title {
      color: #555;
      font-size: 40px;
    }
  }

  .actions {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    /*flex-direction: column;*/
    text-align: center;

    .action {
      padding: 10px 40px;
      width: 200px;
      background: #ffbc0d;
      color: #333;
      font-weight: bold;
      border-radius: 5px;
    }

    .text {
      color: #222;
      font-size: 16px;
    }
  }

  .download-pop {
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    position: absolute;
    width: 100vw;
    top: 0;

    .download-block {
      background: #fff;
      width: calc(100% - 76px);
      margin: auto;
      margin-top: calc(50vh - 100px);
      border-radius: 10px;
      padding: 25px 20px;
      font-size: 14px;
      color: #222;
      position: relative;

      .download-title {
        font-weight: bold;
      }

      .download-text {
        margin-top: 20px;
      }

      .download-btn {
        background: #FFBC0D;
        width: 180px;
        height: 38px;
        border-radius: 50px;
        text-align: center;
        line-height: 38px;
        margin: auto;
        margin-top: 20px;
        font-size: 16px;
        font-weight: bold;
      }

      .download-close {
        position: absolute;
        width: 30px;
        height: 30px;
        right: calc(50% - 15px);
        bottom: -45px;
      }
    }
  }
}