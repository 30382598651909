.reward-detail {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  padding: 18px 15px 20px;
  background-color: #eee;
  text-align: center;

  &::-webkit-scrollbar {
    display: none;
  }
  .reward-detail-item {
    position: relative;
    background-color: #fff;
    display: flex;
    width: 100%;
    height: 118px;
    border-radius: 6px;
    margin-top: 10px;
    align-items: center;
    padding-left: 10px;
  }
  .yellow-div {
    position: absolute;
    background-color: #f5bf44;
    height: 100%;
    width: 5px;
    top: 0;
    left: 0;
    border-radius: 6px 0 0 6px;
  }
  .reward-img-container {
    width: 96px;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .reward-detail-container {
    margin-left: 10px;
    flex: 1;
    text-align: start;
    .reward-tag-title {
      border-radius: 3px;
      border: 1px solid rgba(217, 0, 7, 1);
      padding: 3px 5px;
      max-width: 50px;
      line-height: 10px;
      color: rgba(217, 0, 7, 100);
      font-size: 9px;
      text-align: center;
      font-family: PingFangSC-Medium;
    }
    .yellow-tag {
      border: 1px solid rgba(255, 188, 13, 1);
      color: rgba(255, 188, 13, 100);
    }
    .reward-name {
      margin-top: 5px;
      height: 15px;
      line-height: 15px;
      color: rgba(34, 34, 34, 100);
      font-size: 15px;
      font-family: PingFangSC-Medium;
    }
    .reward-record-time {
      margin-top: 5px;
      height: 10px;
      line-height: 10px;
      color: rgba(102, 102, 102, 100);
      font-size: 10px;
      text-align: left;
      font-family: PingFangSC-Light;
    }
    .reward-time-text {
      margin-top: 5px;
      height: 10px;
      line-height: 10px;
      color: rgba(102, 102, 102, 100);
      font-size: 10px;
      text-align: left;
      font-family: PingFangSC-Light;
    }
    .btn-text {
      margin-top: 10px;
      height: 12px;
      line-height: 12px;
      color: rgba(34, 34, 34, 100);
      font-size: 12px;
      text-align: left;
      font-family: PingFangSC-Regular;
      .btn-img {
        width: 10px;
        height: 10px;
        transform: rotate(270deg);
      }
    }
  }
}

.null-reward {
  width: calc(100vw - 40px);
  height: 427px;
  display: flex;
  text-align: center;
  margin-left: 20px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #666;
  background: #fff;
  .null-reward-img {
    width: 180px;
    height: 180px;
  }
}
