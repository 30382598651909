.root {
  height: 100%;
  font-size: 0;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'SpeedeeApp-Bold';
  src: url("../font/Speedee_Bd.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

img:not([src]) {
  opacity: 0;
}

.couponWrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  box-sizing: border-box;
  background-size: contain;
  background-position: top;
  background-repeat: repeat;
  // .bgImg {
  //   height: 100vh;
  //   width: 100vw;
  //   position: absolute;
  //   z-index: 1;
  //   left: 0;
  //   top: 0;
  // }

  .contentWrapper {
    position: absolute;
    padding: 35Px 20Px 0;
    z-index: 2;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;

    .slogan {
      box-sizing: content-box;
      margin-top: 20Px;
      margin: 0 5Px;
      width: 100%;
    }

    .machineWrapper {
      margin-top: 10Px;
      position: relative;
      width: 100%;
      .couponGif {
        position: absolute;
        top: 97Px;
        left: 28Px;
      }

      .gifFadeOut {
        animation: gifFadeOutMov 0.4s cubic-bezier(0.65, 0, 0.35, 1);
        animation-fill-mode: forwards;

        @keyframes gifFadeOutMov {
          0% {
            opacity: 1;
          }

          100% {
            opacity: 0;
          }
        }
      }

      .singleCardWrapper {
        position: absolute;
        left: 36Px;
        box-sizing: border-box;
        overflow: hidden;

        .topShadow {
          position: absolute;
          z-index: 10;
          width: 100%;
        }

        .cardScrollView {
          width: 100%;
          display: flex;
          flex-direction: column;
          overflow-y: scroll;
          transform: translateY(-37Px);

          
          .singleItem {
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;

            .cardImg {
              background-color: #fff;
              padding: 15Px 10Px 0;
              box-sizing: content-box;
              width: calc(100% - 20Px);

              .couponPic {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 5Px;
              }
            }
  
            .couponInfo {
              display: flex;
              width: 100%;
              justify-content: space-between;
              padding: 5Px 10Px 8Px;
              background-color: #fff;
              box-sizing: border-box;
    
              .title {
                font-family: PingFang SC;
                font-size: 16Px;
                font-weight: 600;
                line-height: 22Px;
                color: #222222;
                margin-top: 6Px;
                width: 180Px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
              
              .smallFont {
                font-size: 14Px;
              }

              .priceWrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
     
    
                .priceInfo {
                  display: flex;
                  justify-content: flex-end;
                  align-items: baseline;
    
                  .unit {
                    font-family: 'SpeedeeApp-Bold, PingFang SC';
                    font-size: 14Px;
                    font-weight: 700;
                    line-height: 19Px;
                    color: #DB0007;
                    display: inline-block;
                    margin-right: 3Px;
                  }
    
                  .price {
                    font-family: 'SpeedeeApp-Bold', PingFang SC;
                    font-weight: 700;
                    line-height: 21Px;
                    color: #DB0007;
                    display: inline-block;
                  }
    
                  .beforePrice {
                    font-size: 21Px;
                  }
    
                  .afterPrice {
                    font-size: 18Px;
                  }
                }
    
                .txt {
                  font-family: PingFang SC;
                  font-size: 10Px;
                  font-weight: 600;
                  line-height: 10Px;
                  color: #222222;
                  font-size: 10px;  // 改为12px作为基准
                }
              }
            }
            .lowerEdge {
              margin-top: -1Px;
            }
          }
        }

      }

      .cardListContentWrapper {
        position: absolute;
        box-sizing: border-box;

        .topShadow {
          position: absolute;
          z-index: 10;
          width: 100%;
          top: 0;
        }

        .bottomShadow {
          position: absolute;
          bottom: -1Px;
          z-index: 10;
          width: 100%;
          opacity: 0;
          display: flex;

          img {
            width: 100%;
            display: block;  // 移除图片默认的 inline 属性
            border: none;    // 移除默认边框
            outline: none;   // 移除默认轮廓
            max-width: 100%;
          }
        }


        .cardListWrapper {
          position: absolute;
          box-sizing: border-box;
          overflow: scroll;


          .cardScrollView {
            width: 100%;
            display: flex;
            overflow-y: scroll;

            .innerWrapper {
              position: absolute;
              bottom: 0;
              display: flex;
              flex-direction: column;
              width: 100%;
              transform: translateY(-50Px);

              .listItem {
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                
    
                .content {
                  position: relative;  // 添加相对定位

                  &::before {  // 添加伪元素作为上边线
                    content: '';
                    position: absolute;
                    top: -1Px;
                    left: 0;
                    width: 100%;
                    height: 2Px;
                    background-color: #ffffff;
                  }
                  &::after {  // 添加伪元素作为下边线
                    content: '';
                    position: absolute;
                    bottom: -1Px;
                    left: 0;
                    width: 100%;
                    height: 2Px;
                    background-color: #ffffff;
                  }

                  background-color: #ffffff;
                  margin: 0;
                  padding: 10Px 15Px 10Px 10Px;
                  box-sizing: border-box;
                  display: flex;
                  align-items: center;
    
                  .couponImg {
                    width: 70Px;
                    height: 70Px;
                    display: block;  // 移除图片默认的 inline 属性
                    border: none;    // 移除默认边框
                    outline: none;   // 移除默认轮廓
                    max-width: 100%;
                  }
    
                  .line {
                    border: 1Px dashed #c4c4c4;
                    height: 100%;
                    margin: 0 15Px 0 10Px;
                  }
    
                  .infoWrapper {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    margin-top: 5Px;
                    overflow: hidden;
                    
                    .title {
                      font-family: PingFang SC;
                      font-size: 14Px;
                      line-height: 20Px;
                      color: #222222;
                      font-weight: 600;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
              
                      &.smallFont {
                        font-size: 12px;
                      }
                    }
    
                    .smallFont {
                      font-size: 12Px;
                    }
                    .date {
                      font-family: PingFang SC;
                      font-size: 10Px;
                      font-weight: 400;
                      line-height: 10Px;
                      color: #999;
    
                    }
    
                    .priceWrapper {
                      display: flex;
                      color: #DB0007;
                      margin-top: 3Px;
                      align-items: baseline;
    
                      .unit {
                        font-family: SpeedeeApp-Bold;
                        font-size: 14Px;
                        font-weight: 700;
                        line-height: 19Px;
                        color: #DB0007;
                        display: inline-block;
                        margin-right: 3Px;
                      }
      
                      .price {
                        font-family: 'SpeedeeApp-Bold', PingFang SC;
                        font-size: 21Px;
                        font-weight: 700;
                        line-height: 27Px;
                        color: #DB0007;
                        display: inline-block;
                      }
    
                      .beforePrice {
                        font-size: 24Px;
                      }
    
                      .afterPrice {
                        font-size: 21Px;
                        line-break: 26Px;
                      }
                    }
                  }
                }
    
                img {
                  display: block;  // 移除图片默认的 inline 属性
                  border: none;    // 移除默认边框
                  outline: none;   // 移除默认轮廓
                  max-width: 100%;
                }
                .upperEdge {
                  // margin-top: -1Px;
                  display: block;
                }
    
                .lowerEdge {
                  // margin-top: -1Px;
                  display: block;
                }
              }
            }
        
            .cardListWrapperTransfromDown {
              animation: cardListDownMov 1.5s cubic-bezier(0.33, 1, 0.68, 1);
              animation-fill-mode: forwards;
              animation-delay: 0.4s;
      
              @keyframes cardListDownMov {
                0% {
                  transform: translateY(-50Px);
                }
      
                100% {
                  transform: translateY(calc(100% - var(--listItemCardHeightRatio))); 
                }
              }
            }
          }

        }
      }

      .cardSingleWrapperFadeIn {
        animation: singleFadeIn 0.2s cubic-bezier(0.65, 0, 0.35, 1);
        animation-fill-mode: forwards;
        animation-delay: 0.4s;
        opacity: 0;

        @keyframes singleFadeIn {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }
      }

      .cardListWrapperFadeIn {
        animation: singleCardFadeInMov 0.4s cubic-bezier(0.65, 0, 0.35, 1);
        animation-fill-mode: forwards;
        // animation-delay: 0.4s;

        @keyframes singleCardFadeInMov {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }
      }

      .singleCardWrapperTransfromDown {
        animation: singleCardDownMov 0.8s cubic-bezier(0.33, 1, 0.68, 1);
        animation-fill-mode: forwards;
        animation-delay: 0.4s;

        @keyframes singleCardDownMov {
          0% {
            transform: translateY(-37Px);
          }

          100% {
            transform: translateY(0Px);
          }
        }
      }


      .bottomShadowFadeIn {
        animation: bottomShadowMov 0.2s cubic-bezier(0.65, 0, 0.35, 1);
        animation-fill-mode: forwards;
        // animation-delay: 0.4s;

        @keyframes bottomShadowMov {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }
      }

      .bottomShadowFadeOut {
        animation: bottomShadowFadeOutMov 0.2s cubic-bezier(0.65, 0, 0.35, 1);
        animation-fill-mode: forwards;

        @keyframes bottomShadowFadeOutMov {
          0% {
            opacity: 1;
          }

          100% {
            opacity: 0;
          }
        }
      }
    }

    // animation-delay: 2s;

    .actionButton {
      margin-top: 25Px;
      width: 100%;
    }

    .ruleContent {
      width: 100%;
      padding: 0 5Px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 25Px 0 0;


      .title {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
        color: #461E00;
        text-align: center;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 600;
      }

      .content {
        width: 100%;
        color: #461E00;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        /* 150% */
        padding-bottom: 34px;
      }
    }
  }

}

.emptyWrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 184px;
    height: 180px;

  }

  .emptyText {
    font-family: PingFang SC;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    color: #222222;
    margin-top: 20px;
  }

  .emptySubText {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #222222;
  }
}

.infoModalWrapper {
  width: 295px !important;
  height: 185px !important;
  top: 28%;
  position: relative;

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-body {
    padding: 20PX 20PX;
  }

  .ant-modal {
    top: 45% !important;
    transform: translateY(-50%) !important;
  }
  .infoModal {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .title {
      color: #222;
      text-align: center;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px; /* 120% */
      margin: 0;
    }

    .actionBtn {
      background-color: #FFBC0D;
      width: 255px;
      height: 38px;
      margin-top: 20px;
      text-align: center;
      color: #222;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 38px;
      border-radius: 50px;
    }

    .closeIcon {
      bottom:  -55Px;
      width: 30Px;
      height: 30Px;
      position: absolute;
    }
  }
}