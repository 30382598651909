// @import '~video-react/styles/scss/video-react';

.demo-wrapper {
  min-height: 100vh;
  padding: 20px;
  font-size: 14px;
  .video-type {
    margin: 10px;
    margin-top: 20px;
    font-weight: 500;
    color: #222;
  }
  .video-react-poster {
    background-size: cover;
  }
  .vp-bar {
    // color: #FFBC0D;
  }
  .video-item {
    margin: 5px;
  }
  .video-react-big-play-button {
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }
  .video-react-big-play-button.video-react-big-play-button-center{
  margin-top: 0;
  margin-left: 0;
  transform: translate(-50%, -50%);
 }
  .video-react-play-progress{
    background: #FFBC0D;

  }
}