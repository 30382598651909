.invite-download {
  width: 100vw;
  padding-bottom: 30px;
  .activity-expire {
    width: calc(100vw - 40px);
    height: 427px;
    display: flex;
    text-align: center;
    margin-left: 20px;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #666;
    background: #fff;

    .activity-expire-content {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .activity-expire-btn {
      margin-top: 15px;
      width: 207px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #FFBC0D;
      height: 38px;
      color: #000;
      font-size: 16px;
      border-radius: 19px;
      border: none;
    }
    img {
      width: 180px;
      height: 180px;
    }
  }
  img {
    width: 100%;
    pointer-events:none;
    user-select:none;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
  }
  .invite-cover {
    position: relative;
    width: 100%;
    .activity-rule {
      position: absolute;
      z-index: 4;
      right: 0;
      top: 30px;
      width: 29px;
      height: 70px;
      background-color: rgba(0, 0, 0, 0.4);
      color: rgba(255, 255, 255, 100);
      font-size: 12px;
      word-break: break-all;
      padding: 9px 8px;
      line-height: 14px;
      border-radius: 5px 0 0 5px;
    }
    .invite-cover-time {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      &-detail {
        background-color: #000;
        color: #fff;
        padding-left: 2px;
        padding-right: 2px;
        margin-left: 2px;
        margin-right: 2px;
        border-radius: 2px;
      }
    }
    .invite-cover-bg {
      display: block;
    }
    .invite-cover-btn-box {
      position: absolute;
      bottom: 10%;
    }
    .invite-cover-btn {
      z-index: 3;
      background: transparent;
      img {
        pointer-events:none;
        user-select:none;
        -webkit-touch-callout:none;
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
      }
    }
    .more-invite-container {
      width: 100%;
      text-align: center;
      z-index: 3;
      background: transparent;
      margin-bottom: -25px;
    }
    .more-invite-img {
      width: 120px;
    }
  }

  .invite-detail {
    margin-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    .invite-detail-item-third {
      position: relative;
      margin-top: 38px;
    }
    .invite-detail-item {
      position: relative;
      margin-top: 20px;
    }
    .item-top, .more-invite-img {
      position: absolute;
      z-index: 3;
      top: -18px;
    }
    .progress-container {
      position: relative;
      width: 100%;
      overflow: scroll;
      margin-top: 15px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .progress-container-no-login {
      position: relative;
      width: 100%;
      overflow: scroll;
      margin-top: 15px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .more-invite {
      background-color: #fff;
      color: rgba(60, 55, 47, 100);
      font-size: 14px;
      height: 230px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 10px 20px 0;

      .item-img, .item-target {
        width: 70px;
        text-align: center;
        color: rgba(51, 51, 51, 100);
        font-size: 12px;
      }
      .item-img {
        margin-bottom: 23px;
      }
      .item-progress {
        line-height: 1;
        margin-top: 11px;
        margin-bottom: 12px;
      }
      .img-group-img {
        margin-top: 10px;
      }
      .target-group {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        &-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 25px;
          &:last-child {
            margin-right: 0;
          }
        }
        .item-done-img,.item-done-div {
          width: 15px;
          z-index: 4;
          margin-bottom: 8px;
          margin-top: 6px;
          height: 15px;
          position: absolute;
          top: 68px;
        }
        .item-done-div {
          border-radius: 8px;
          background-color: #fbe9c7;
        }
      }
      .more-invite-title {
        width: 100%;
        text-align: center;
        color: #000;
        font-weight: 500;
        font-size: 14px;
        margin-top: 15px;
        .spec-color {
          font-family: futura;
          color: #db0007;
          font-size: 18px;
        }
      }
    }
    .item-reward-content {
      margin: 0 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      border-radius: 10px;
      padding-bottom: 20px;
      .item-reward-content-detail {
        width: calc(100% - 80px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
      }
      .record {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 115px;
        &:first-child {
          margin-right: 25px;
        }
        .record-top {
          display: flex;
          align-items: baseline;
          color: rgba(219, 0, 7, 100);
        }
        .record-top-num {
          font-size: 30px;
          line-height: 1;
          font-weight: 500;
        }
        .record-top-unit {
          font-size: 12px;
          margin-left: 1px;
        }
        .record-bottom {
          margin-top: 6px;
          font-size: 12px;
          color: rgba(102, 102, 102, 100);
        }
      }
    }
  }

  .check-invite-detail {
    margin-top: 15px;
    height: 36px;
    display: flex;
    align-items: center;
    width: 150px;
    justify-content: center;
    img {
      width: 100%;
    }

  }
  .learn-more {
    width: 100%;
    text-align: center;
    margin: 10px auto 0;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-bottom: 10px;
    .arrow-up {
      transform: rotate(180deg);
    }
    img {
      width: 15px;
      height: 15px;
    }
  }
  .invite-person {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px 20px 20px;

    .invite-person-item {
      &:first-child {
        margin-top: 20px;
      }
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item-left {
        .nick-name {
          line-height: 1;
          font-size: 14px;
          font-weight: 500;
          color: #333;
        }
        .tag-name {
          line-height: 1;
          margin-top: 5px;
          font-size: 11px;
          color: rgba(153, 153, 153, 100);
        }
      }
      .item-right {
        font-size: 14px;
        font-weight: 500;
        color: #995733;
      }
    }
  }
}

.null-person {
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 14px;
}

.modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  position: relative;
  .modal-content-title {
    margin-top: 25px;
    font-size: 16px;
    font-weight: 500;
  }
  .modal-content-rule {
    width: 100%;
    height: calc(100% - 150px);
    overflow: scroll;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 20px;
    white-space: pre-line;
  }
  .modal-content-btn {
    position: absolute;
    bottom: 25px;
    margin: 0 auto;
    width: 207px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFBC0D;
    height: 38px;
    color: #000;
    font-size: 16px;
    border-radius: 19px;
  }
}
.modal-content-tip {
  width: 100%;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
  white-space: pre-line;
  margin-top: 10px;
}

.ant-modal-body {
  height: 100%;
  padding: 0;
}
.rule-modal {
  .ant-modal-content {
    width: calc(100% - 36px);
    height: 442px;
    margin: 0 auto;
    border-radius: 10px;

    .ant-modal-footer {
      display: none;
    }
  }
}

.ant-progress-inner {
  height: 5px;
}

.tip-modal {
  .ant-modal-content {
    width: calc(100% - 80px);
    height: 180px;
    border-radius: 10px;
    margin: 165px auto 0;

    .ant-modal-footer {
      display: none;
    }
  }
  .modal-content-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-line;
  }
}
.record-bottom-img-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.invite-download .record-bottom-img-container .record-bottom-img {
  height: 12px;
  width: 141px;
}
