@font-face {
  font-family: Speedee;
  src: url(../font/WOFF2/Speedee_W_Rg.woff2)
}
.store-tool-wrapper {
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
  width: 80%;
  padding-top: 30px;
  max-width: 750px;

  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #222;
  }

  .input-code {
    margin-top: 40px;
    position: relative;

    input {
      border: 1px solid #999999;
      text-align: center;
      border-radius: 100px;
      padding: 8px 0;
      width: 100%;
      -webkit-appearance: unset;
      color: #222;
      font-size: 16px;
      font-family: Speedee;
    }
    input:focus {
      outline:none;
    }

    input::-webkit-input-placeholder {
      color: #999;
    }
    input:focus::-webkit-input-placeholder {
      color: transparent;
    }

    :-ms-input-placeholder {
      color: #999;
    }

    .clear {
      background: #CACACA 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      color: #fff;
      text-align: center;
      right: 8px;
      top: 10px;
      border-radius: 100px;
    }
  }

  .create-qr {
    background: #FFBC0D;
    width: 180px;
    margin: 0 auto;
    margin-top:32px;
    border-radius: 24px;
    color: #000;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .canvas-image {
    width: 90%;
  }

  .toast {
    .point {
      width: 14px;
      height: 14px;
      line-height: 14px;
      vertical-align: 0px;
      margin-right: 6px;
      background: #FFBC0D;
      border-radius: 100%;
      display: inline-block;
      color: #fff;
    }

    padding: 10px 0;
    width: 278px;
    margin: 0 auto;
    line-height: 20px;
    height: 20px;
    border-radius: 6px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  }

  .qr-type {
    font-size: 14px;
    color: #222;
    font-weight: 600;
    width: 80%;
    margin: 0 auto;
    text-align: left;
    position: relative;
    left: 20px;
    // top: 10px;

    .text {
      font-weight: 400;
      font-size: 13px;
    }
  }

  .qr-image {
    width: 80%;
    margin: 0 auto;
  }

  .save-image {
    font-size: 15px;
    margin-top: 16px;
    color: #222;
    font-weight: 500;
  }
  .fix-help {
    position: fixed;
    bottom: 20px;
    width: 80%;
  }
  .help, .fix-help{
    font-size: 14px;
    color: #0086D6;
    .question {
      text-align: center;
    }
    .email {
      color: #222;
      margin-top: -10px;
    }
  }
}

@media (min-height: 570px) {
  .help {
    position: fixed;
    bottom: 20px;
    width: 80%;
  }
}
@media (max-height: 570px) {
  .help {
    margin: 0 auto;
    padding-bottom: 10px;
  }
}