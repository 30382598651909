.qr-sign-in {
  font-family: "PingFangSC-Regular,sans-serif,tahoma";

  .header {
    display: flex;
    /*background: green;*/
    /*color: white;*/
    justify-content: center;
    align-items: center;
    padding-top: 150px;

    .loading {
      height: 100px;
    }

    .logo {
      width: 200px;
    }

    .title {
      color: #555;
      font-size: 40px;
    }
  }

  .actions {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    /*flex-direction: column;*/
    text-align: center;

    .action {
      padding: 10px 40px;
      width: 200px;
      background: #ffbc0d;
      color: #333;
      font-weight: bold;
      border-radius: 5px;
    }

    .text {
      color: #222;
      font-size: 16px;
    }
  }
}