.lottery-wrapper {
  background: url('https://img.mcd.cn/gallery/24f760a387d61b52.png') repeat;
  height: 100vh;
  background-size: contain;

  .lottery-pop {
    background-color: rgba(0, 0, 0, 0.65);
    height: 100vh;
    width: 100%;
    padding-top: calc(50vh - 160px);

    .lottery-container {
      margin: 0 40px;
      background: #fff;
      border-radius: 20px;
      padding: 20px;
      color: #222;

      .title {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 15px;
      }

      .lottery-block {
        background: #F8F8FD;
        border-radius: 6px;
        padding: 15px 10px;


        .block-cell {
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          margin-bottom: 10px;

          .cell-label {
            font-family: PingFangSC-Light;
          }
        }

        .block-cell:last-child {
          margin-bottom: 0;
        }
      }
      .trade-result-desc {
        color: #222;
        font-size: 15px;
      }

      .notice-desc {
        color: #DB0007;
        font-size: 13px;
        font-family: PingFangSC-Light;
        margin-top: 10px;

      }

      .btn-set {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 42px;
        line-height: 42px;
        margin-top: 20px;
        font-size: 18px;

        .cancel-btn {
          border-radius: 100px;
          border: 1px solid #D9D9D9;
          color: #333;
          width: 50%;
          margin-right: 8px;
        }

        .ok-btn {
          background: #4545FF;
          border-radius: 100px;
          color: #fff;
          width: 50%;
          margin-left: 8px;
          font-weight: bold;
        }

        .single-btn {
          width: 195px;
          font-weight: bold;
          font-weight: bold;
        }
      }
    }
  }
}