@barHeight: 1.2rem;

body {
  font-size: 0.49rem;
}

.modal-css {
  border-radius: 8px;
  padding: 25px 40px;

  .alert-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ok-button {
      margin-top: 20px;
      width: 90%;
      background-color: #FFBC0D;
      font-weight: 14px;
      padding: 8px 0;
      border-radius: 50vw;
      text-align: center;
      font-weight: bold;
    }
  }

  .react-responsive-modal-closeButton {
    display: none;
  }
}

.container {
  height: 100vh;
  touch-action: none;
  .header {
    position: relative;
    img {
      width: 100%;
      vertical-align: middle;
    }

    .jump-span {
      position: absolute;
      z-index: 5;
      top: 0;
      height: 100%;
    }

    .jump-left {
      width: 64%;
      left: 0;
    }

    .jump-right {
      width: 36%;
      right: 0;
    }

  }

  .banner-img {
    width: 100%;
    // padding-bottom: 1.5rem;
    //background-size:cover;
    //background-repeat:no-repeat;
    //background-position:center center;
    //height: 100vh;
    //background-image: url("https://img.mcd.cn/gallery/683820201223100529076.jpg");
  }

  .footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 10;
  }

  .footer-app {
    margin-top: 1.5rem;
    height: 1.5rem;
    background-color: rgba(255, 255, 255, 0.8);

    .btn-download {
      background-color: #FFBC0D;
      padding: 0.2rem 2rem;
      border-radius: 2rem;
      color: #222222;
      font-weight: 700;
      font-size: 0.3rem;
    }
  }

  .footer-cdn {
    margin-top: @barHeight;

    .bottom-bar {
      font-size: 0.24rem;
      color: #FFF;
      background-color: #CB2C20;
      height: @barHeight;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0 12px;
      align-items: center;

      a.link-privacy {
        display: block;
        height: @barHeight;

        img.img-bar {
          height: 100%;
        }
      }

      img.logo-in-bar {
        width: 1rem;
      }

      .info-block {
        display: flex;
      }

      a.btn-privacy {
        display: inline-block;
        text-decoration: none;
        background-color: #FFF;
        color: #CB2C20;
        padding: 2px 6px 2px 15px;
        border-radius: 2rem;
        transform: scale(0.9);
        text-align: center;
      }

      ul {
        padding: 0;
        flex-shrink: 0;

        li {
          transform: scale(0.8);
          padding: 0;
          text-indent: -8px
        }
      }

      ul.info-1 {
        margin-left: 6px;
      }

      ul.info-2 {
        margin-left: -10px;
      }
    }
  }

  .download-pop {
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    position: absolute;
    width: 100vw;
    top: 0;

    .download-block {
      background: #fff;
      width: calc(100% - 76px);
      margin: auto;
      margin-top: calc(50vh - 100px);
      border-radius: 10px;
      padding: 25px 20px;
      font-size: 14px;
      color: #222;
      position: relative;

      .download-title {
        font-weight: bold;
      }

      .download-text {
        margin-top: 20px;
      }

      .download-btn {
        background: #FFBC0D;
        width: 180px;
        height: 38px;
        border-radius: 50px;
        text-align: center;
        line-height: 38px;
        margin: auto;
        margin-top: 20px;
        font-size: 16px;
        font-weight: bold;
      }

      .download-close {
        position: absolute;
        width: 30px;
        height: 30px;
        right: calc(50% - 15px);
        bottom: -45px;
        z-index: 100;
      }
    }
  }
}